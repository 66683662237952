<template>
  <v-list-item style="border-bottom: 1px solid #e5e5e5;" @click="preOpenConsult(kanban)" v-if="kanban.doctor" class="px-2 pr-2">
    <v-avatar rounded size="41" class="mr-2" dark color="blue darken-2">
      <v-icon color="white">mdi-card-account-details-outline</v-icon>
    </v-avatar>
    <v-list-item-content>
      <v-list-item-title class="fonte">
        {{ kanban.doctor.nome || "Consulta não concluída..." }}
      </v-list-item-title>
      <v-list-item-subtitle v-if="kanban.compra.status && kanban.compra.status == 'agendada'">
        <span class="fonteMini">
          {{ kanban.compra.disponibility.visit_date }} - {{ kanban.compra.disponibility.visit_hour_start }} às {{ kanban.compra.disponibility.visit_hour_end }}
        </span>
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        <span class="fonte fonteMini">
          # {{ kanban.id_transacao }}
        </span>
        <!-- <MembersPlugin
          origin="cardsale"
          :dataOrigin="{ ...kanban, state: 'progress-view' }"
        /> -->
        
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <span class="fonteMini fonte">
        {{ kanban.compra.status }}
      </span>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { mapActions } from "vuex";
import MembersPlugin from "@/apps/CosmosPluginStore/plugins/members/index.vue";

export default {
  props: {
    kanban: {
      type: Object,
      required: true
    }
  },
  components: {
    MembersPlugin
  },
  data() {
    return {
      initialized: false
    };
  },
  methods: {
    ...mapActions(["abre_modal_view_gestordepedido", "listar_transacoes"]),
    preOpenConsult(kanban) {
      this.$store.dispatch("enableLoading");
      this.$run("transacoes/view-transaction", {
        _id: kanban._id
      }).then(res => {
        this.$store.dispatch("disableLoading");
        this.abre_modal_view_gestordepedido({ ...res.data });
      });
    },
    openWhats(client) {
      window.open(
        `https://api.whatsapp.com/send?phone=55${client.telefone}&text=Olá, ${client.nome}!`
      );
    },
    update_status(compra, status) {
      this.$run("caixas/atualizar-status-do-pedido", {
        ...compra,
        novo_status: status.value
      })
        .then(() => {
          this.$store.dispatch("createGlobalMessage", {
            type: "success",
            message: `Status do pedido atualizado para ${status.text}`,
            timeout: 6000
          });
          this.listar_transacoes();
        })
        .catch(error => {
          this.$store.dispatch("createGlobalMessage", {
            type: "info",
            message: error.response.data.message,
            timeout: 6000
          });
        });
    }
  },
  mounted() {
    setTimeout(() => {
      this.initialized = true;
    }, 2500);
  }
};
</script>

<style>
.card-list-item-mini {
  border-bottom: 1px solid #e5e5e5;
  padding-left: 5px;
}
.cool-shaddow {
  box-shadow: -1px -2px 60px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: -1px -2px 60px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -1px -2px 60px 2px rgba(0, 0, 0, 0.1);
}
</style>
